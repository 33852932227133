/** @jsx jsx */
import { jsx } from "theme-ui"

const Logo = (props) => (
    <svg
      sx={{
        height: props.size,
        width: props.size,
      }}
      xmlns="http://www.w3.org/2000/svg"
      version="1"
      viewBox="0 0 200 200"
    >
      <g fill={props.color} stroke="none">
      <path
        d="M841 1855c-99-22-191-54-251-87-217-119-365-300-437-535-24-79-27-104-27-243-1-121 3-169 17-220 52-183 161-356 292-460 144-114 303-181 474-198 385-39 745 171 899 523 56 130 67 185 66 355 0 139-3 164-27 243-59 192-164 343-320 458-154 115-309 169-502 175-82 2-140-1-184-11zm249-870V350H910v1270h180V985zm-330 320v-85H580v170h180v-85zm650 0v-85h-170v170h170v-85zM760 845V580H580v530h180V845zm650 0V580h-170v530h170V845z"
        transform="matrix(.1 0 0 -.1 0 200)"
      ></path>
      </g>
    </svg>
)

Logo.defaultProps = {
  color: "white",
}

export default Logo
